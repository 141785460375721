@import "App.css";

.red-text {
  color: red;
}

.connect-wallet-button {
  color: white;
}

.account-connected {
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 0.8em;
  color: white;
  font-family: "BDRmono 2006";
  flex-direction: row;
  display: flex;
  align-items: center;
}

.home {
  position: absolute;
  top: 5px;
  left: 15px;
  font-size: 0.8em;
  color: white;
  font-family: "BDRmono 2006";
  flex-direction: row;
  display: flex;
  align-items: center;
}

.red-dot {
  background-color: red;
  box-shadow: 0px 0px 5px 5px #8b0000;
  height: 10px;
  width: 10px;
  margin-right: 10px;
  border-radius: 10px;
  -webkit-animation: flicker-5 8s linear infinite both;
  animation: flicker-5 8s linear infinite both;
  cursor: pointer;
}

.button-wrapper {
  padding-top: 20px;
}

.connect-wallet-button {
  background-color: transparent;
  border: 1px solid white;
  font-family: "BDRmono 2006";
  font-size: 0.5em;
  padding: 5px 15px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: all 200ms linear;
}

.connect-wallet-button:hover {
  font-size: 0.6em;
  transition: all 200ms linear;
}

.army-text {
  color: white;
  font-family: Dystopian;
  padding-top: 20px;
}

.App {
  background-color: black;
}

.coming-soon-subtitle {
  font-size: 0.45em;
}

.mystuff-row {
  justify-content: center;
}

.deets {
  font-family: "BDRmono 2006";
  font-size: 0.5em;
}

.scrollable {
  overflow-y: scroll;
}

.game-container {
  width: 80vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Dystopian;
}

.iframe-container {
  width: 100%;
  height: 100%;
}