@font-face {
  font-family: "BDRmono 2006";
  src: url(./fonts/BDRmono2006.otf);
}

@font-face {
  font-family: "Dystopian";
  src: url(./fonts/Dystopian\ Bold.otf);
}

@font-face {
  font-family: "Dystopian";
  src: url(./fonts/Dystopian\ Light.otf);
}


.App {
    text-align: center;
}

.App-logo {
    width: 40vw;
}

.red {
  color: red;
  font-size: 0.5em;
  transition: all 200ms linear;
}

.botfont {
  font-family: "Dystopian";
}

.mobile-nav {
  display: flex;
  justify-content: space-between;
}

.mobile-menu {
  background-color: black;
  opacity: 1.0;
  z-index: 300;
}

.red:hover {
  color: white !important;
  transition: all 200ms linear;
}

.white-border {
  border: 2px solid white !important;
}

.moon-bg {
  position: absolute;
  top: 60px;
  right: 30px;
  z-index: 1;
  opacity: 0.2;
}

.navheader {
  width: 100%;
  background-color: #ffffff;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 50px;
  top: -50;
  z-index: 99;
}

.flicker-in {
  -webkit-animation: flicker-in-1 2s linear both;
  animation: flicker-in-1 2s linear both;
}

.nav {
  z-index: 120;
}

.nav li {
  display:inline-block;
  vertical-align: middle;
  cursor: pointer;
  z-index: 150;
}

.nav li a {
  color: #FFF;
  font-size: 16px;
  font-family: "BDRmono 2006";
  text-decoration: none;
  margin-right: 20px;
  text-align: center;
  line-height: 80px;
}

.nav li a:hover {
  color: red;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        transition: all 200ms ease-in-out;
        overflow: hidden;
        display: inline-block;
        margin-bottom: 20px;
    }

    .App-logo:hover {
        transform: rotate(-2deg);
    }

    .App-logo:after {
        content: "";
        position: absolute;
          top: -50%;
        left: -60%;
        width: 20%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);
      
        background: rgba(255, 255, 255, 0.13);
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.13) 0%,
          rgba(255, 255, 255, 0.13) 77%,
          rgba(255, 255, 255, 0.5) 92%,
          rgba(255, 255, 255, 0.0) 100%
        );
      }
      
      /* Hover state - trigger effect */
      .App-logo:hover:after {
        opacity: 1;
        left: 130%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
      }
      
      /* Active state */
      .App-logo:active:after {
        opacity: 0;
      }
}

.logo-wrapper {
    width: fit-content;
    height: auto;
}

.App-header {
    min-height: 100vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

@media (max-width: 768px) {
    .socials a {
        padding: 5vw !important;
    }

    .promo-video {
      width: 100%;
    }


    .video-row {
      flex-wrap: wrap;
      margin-left: 0px !important;
      gap: 0px;
    }
}

.promo-video {
  width: 100%;
  max-width: 480px;
}
  

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#tsparticles {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    z-index: -1;
}

.socials {
    display: flex;
    flex-direction: row;
}

.socials a {
    margin: 2vw;
}

.social-link {
    transition: all 500ms ease-in-out;
}

.social-link:hover {
    transform: scale(1.1) !important;
}

.coming-soon-text {
    font-family: "BDRmono 2006";
    -webkit-animation: flicker-5 8s linear infinite both;
    animation: flicker-5 8s linear infinite both;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.section-header {
  font-family: "BDRmono 2006";
  -webkit-animation: flicker-5 8s linear infinite both;
  animation: flicker-5 8s linear infinite both;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.section-body {
  font-family: "Dystopian";
  font-weight: light;
  padding: 0px 20px;
  max-width: 1000px;
  font-size: 0.8em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.larger {
  max-width: 1200px !important;
  margin-left: -200px;
}

.smaller-text {
  font-size: 0.6em;
}

.section-body-text-only {
  font-family: "Dystopian";
  font-weight: light;
  font-size: 0.8em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a.flicker {
  -webkit-animation: flicker-5 8s linear infinite both;
  animation: flicker-5 8s linear infinite both;
  text-decoration: none;
  color: cyan;
  position: relative;
  transition: all 500ms ease-in-out;
}

a.flicker-red {
  color: red;
}

a.flicker:hover {
  color: white;
  transition: all 500ms ease-in-out;
}

a.flicker::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: white;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

a.noflicker:hover::before {
  transform: scaleX(1);
}

a.noflicker {
  text-decoration: none;
  color: cyan;
  position: relative;
  transition: all 500ms ease-in-out;
}

a.noflicker:hover {
  color: white;
  transition: all 500ms ease-in-out;
}

a.noflicker::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: white;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

a.noflicker:hover::before {
  transform: scaleX(1);
}

.countdown-text {
    font-family: "Dystopian";
    font-size: 3em;
    font-weight: bold;
    /* -webkit-animation: flicker-3 2.5s linear infinite both;
    animation: flicker-3 2.5s linear infinite both; */
    margin-bottom: 20px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.bottom-right {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.bottom-left {
  position: absolute;
  left: 10px;
  bottom: 10px;
  -webkit-animation: heartbeat 1.5s ease-in-out 1s infinite both;
  animation: heartbeat 1.5s ease-in-out 1s infinite both;
  cursor: pointer;
}

.roadmap-image {
  padding-top: 20px;
  width: 80vw;
  max-width: 1200px;
  max-height: 95vh;
}

.roadmap-image-mobile {
  padding-top: 50px;
  width: 92vw;
}

/* .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.col {
  display: flex;
  flex-direction: col;
  flex-wrap: wrap;
} */

img {
  user-drag: none;  
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.mouse {
  display: block;
  width: 23px;
  height: 40px;
  border-radius: 13px;
  border: 2px solid #ff0000;
  position: absolute;
  /* top: 50%; */
  right: 10px;
  bottom: 10px;
}

.mouse span {
    display: block;
    margin: 6px auto;
    width: 3px;
    height: 7px;
    border-radius: 100%;
    background: #ff0000;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: scroll;
    animation-name: scroll;
}

.carousel-container {
  width: 200px;
}

.video-row {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-left: -100px;
}

/* .nav-bar {
  position: sticky;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: white;
  font-family: "BDRMono 2006";
  gap: 30px;
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 0.8em;
} */


@-webkit-keyframes scroll {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }
}

@keyframes scroll {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(12px);
    -ms-transform: translateY(12px);
    transform: translateY(12px);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-12-9 10:46:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * @animation flicker-in-1
 * ----------------------------------------
 */
 @-webkit-keyframes flicker-in-1 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  10.1% {
    opacity: 1;
  }
  10.2% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  20.1% {
    opacity: 1;
  }
  20.6% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  30.1% {
    opacity: 1;
  }
  30.5% {
    opacity: 1;
  }
  30.6% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  45.1% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  55.1% {
    opacity: 0;
  }
  57% {
    opacity: 0;
  }
  57.1% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  60.1% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  65.1% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  75.1% {
    opacity: 0;
  }
  77% {
    opacity: 0;
  }
  77.1% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  85.1% {
    opacity: 0;
  }
  86% {
    opacity: 0;
  }
  86.1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flicker-in-1 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  10.1% {
    opacity: 1;
  }
  10.2% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  20.1% {
    opacity: 1;
  }
  20.6% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  30.1% {
    opacity: 1;
  }
  30.5% {
    opacity: 1;
  }
  30.6% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  45.1% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  55.1% {
    opacity: 0;
  }
  57% {
    opacity: 0;
  }
  57.1% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  60.1% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  65.1% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  75.1% {
    opacity: 0;
  }
  77% {
    opacity: 0;
  }
  77.1% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  85.1% {
    opacity: 0;
  }
  86% {
    opacity: 0;
  }
  86.1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}


/* ----------------------------------------------
 * Generated by Animista on 2021-12-1 14:36:14
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flicker-3
 * ----------------------------------------
 */
 @-webkit-keyframes flicker-3 {
    0%,
    100% {
      opacity: 1;
    }
    32.98% {
      opacity: 1;
    }
    33% {
      opacity: 0;
    }
    34% {
      opacity: 0;
    }
    34.02% {
      opacity: 1;
    }
    34.98% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    35.9% {
      opacity: 0;
    }
    35.92% {
      opacity: 1;
    }
    38.98% {
      opacity: 1;
    }
    39% {
      opacity: 0;
    }
    39.8% {
      opacity: 0;
    }
    39.82% {
      opacity: 1;
    }
    83.98% {
      opacity: 1;
    }
    84% {
      opacity: 0;
    }
    84.9% {
      opacity: 0;
    }
    84.92% {
      opacity: 1;
    }
  }
  @keyframes flicker-3 {
    0%,
    100% {
      opacity: 1;
    }
    32.98% {
      opacity: 1;
    }
    33% {
      opacity: 0;
    }
    34% {
      opacity: 0;
    }
    34.02% {
      opacity: 1;
    }
    34.98% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    35.9% {
      opacity: 0;
    }
    35.92% {
      opacity: 1;
    }
    38.98% {
      opacity: 1;
    }
    39% {
      opacity: 0;
    }
    39.8% {
      opacity: 0;
    }
    39.82% {
      opacity: 1;
    }
    83.98% {
      opacity: 1;
    }
    84% {
      opacity: 0;
    }
    84.9% {
      opacity: 0;
    }
    84.92% {
      opacity: 1;
    }
  }
  
  /* ----------------------------------------------
 * Generated by Animista on 2021-12-1 14:37:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flicker-5
 * ----------------------------------------
 */
@-webkit-keyframes flicker-5 {
    0%,
    100% {
      opacity: 1;
    }
    0% {
      opacity: 1;
    }
    1% {
      opacity: 1;
    }
    1.02% {
      opacity: 1;
    }
    8.98% {
      opacity: 1;
    }
    9% {
      opacity: 0;
    }
    9.8% {
      opacity: 0;
    }
    9.82% {
      opacity: 1;
    }
    9.48% {
      opacity: 1;
    }
    9.5% {
      opacity: 1;
    }
    9.6% {
      opacity: 1;
    }
    9.62% {
      opacity: 1;
    }
    14.98% {
      opacity: 1;
    }
    15% {
      opacity: 0.5;
    }
    15.8% {
      opacity: 0.5;
    }
    15.82% {
      opacity: 1;
    }
    15.18% {
      opacity: 1;
    }
    15.2% {
      opacity: 0.7;
    }
    16% {
      opacity: 0.7;
    }
    16.02% {
      opacity: 1;
    }
    15.48% {
      opacity: 1;
    }
    15.5% {
      opacity: 0.5;
    }
    16.2% {
      opacity: 0.5;
    }
    16.22% {
      opacity: 1;
    }
    16.98% {
      opacity: 1;
    }
    17% {
      opacity: 1;
    }
    17.8% {
      opacity: 1;
    }
    17.82% {
      opacity: 1;
    }
    20.48% {
      opacity: 1;
    }
    20.5% {
      opacity: 0.9;
    }
    21.3% {
      opacity: 0.9;
    }
    21.32% {
      opacity: 1;
    }
    20.98% {
      opacity: 1;
    }
    21% {
      opacity: 1;
    }
    22% {
      opacity: 1;
    }
    22.02% {
      opacity: 1;
    }
    39.98% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    41% {
      opacity: 1;
    }
    41.02% {
      opacity: 1;
    }
    40.48% {
      opacity: 1;
    }
    40.5% {
      opacity: 0.6;
    }
    41.4% {
      opacity: 0.6;
    }
    41.42% {
      opacity: 1;
    }
    41.98% {
      opacity: 1;
    }
    42% {
      opacity: 1;
    }
    42.8% {
      opacity: 1;
    }
    42.82% {
      opacity: 1;
    }
    59.98% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    61% {
      opacity: 1;
    }
    61.02% {
      opacity: 1;
    }
    60.18% {
      opacity: 1;
    }
    60.2% {
      opacity: 0.2;
    }
    61% {
      opacity: 0.2;
    }
    61.02% {
      opacity: 1;
    }
    60.78% {
      opacity: 1;
    }
    60.8% {
      opacity: 0.4;
    }
    61.6% {
      opacity: 0.4;
    }
    61.62% {
      opacity: 1;
    }
    61.38% {
      opacity: 1;
    }
    61.4% {
      opacity: 0;
    }
    62.2% {
      opacity: 0;
    }
    62.22% {
      opacity: 1;
    }
    61.78% {
      opacity: 1;
    }
    61.8% {
      opacity: 1;
    }
    62.8% {
      opacity: 1;
    }
    62.82% {
      opacity: 1;
    }
    75.98% {
      opacity: 1;
    }
    76% {
      opacity: 1;
    }
    77% {
      opacity: 1;
    }
    77.02% {
      opacity: 1;
    }
    77.98% {
      opacity: 1;
    }
    78% {
      opacity: 0.7;
    }
    78.8% {
      opacity: 0.7;
    }
    78.82% {
      opacity: 1;
    }
    78.98% {
      opacity: 1;
    }
    79% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    80.02% {
      opacity: 1;
    }
    99.98% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
    101% {
      opacity: 1;
    }
    101.02% {
      opacity: 1;
    }
  }
  @keyframes flicker-5 {
    0%,
    100% {
      opacity: 1;
    }
    0% {
      opacity: 1;
    }
    1% {
      opacity: 1;
    }
    1.02% {
      opacity: 1;
    }
    8.98% {
      opacity: 1;
    }
    9% {
      opacity: 0;
    }
    9.8% {
      opacity: 0;
    }
    9.82% {
      opacity: 1;
    }
    9.48% {
      opacity: 1;
    }
    9.5% {
      opacity: 1;
    }
    9.6% {
      opacity: 1;
    }
    9.62% {
      opacity: 1;
    }
    14.98% {
      opacity: 1;
    }
    15% {
      opacity: 0.5;
    }
    15.8% {
      opacity: 0.5;
    }
    15.82% {
      opacity: 1;
    }
    15.18% {
      opacity: 1;
    }
    15.2% {
      opacity: 0.7;
    }
    16% {
      opacity: 0.7;
    }
    16.02% {
      opacity: 1;
    }
    15.48% {
      opacity: 1;
    }
    15.5% {
      opacity: 0.5;
    }
    16.2% {
      opacity: 0.5;
    }
    16.22% {
      opacity: 1;
    }
    16.98% {
      opacity: 1;
    }
    17% {
      opacity: 1;
    }
    17.8% {
      opacity: 1;
    }
    17.82% {
      opacity: 1;
    }
    20.48% {
      opacity: 1;
    }
    20.5% {
      opacity: 0.9;
    }
    21.3% {
      opacity: 0.9;
    }
    21.32% {
      opacity: 1;
    }
    20.98% {
      opacity: 1;
    }
    21% {
      opacity: 1;
    }
    22% {
      opacity: 1;
    }
    22.02% {
      opacity: 1;
    }
    39.98% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    41% {
      opacity: 1;
    }
    41.02% {
      opacity: 1;
    }
    40.48% {
      opacity: 1;
    }
    40.5% {
      opacity: 0.6;
    }
    41.4% {
      opacity: 0.6;
    }
    41.42% {
      opacity: 1;
    }
    41.98% {
      opacity: 1;
    }
    42% {
      opacity: 1;
    }
    42.8% {
      opacity: 1;
    }
    42.82% {
      opacity: 1;
    }
    59.98% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    61% {
      opacity: 1;
    }
    61.02% {
      opacity: 1;
    }
    60.18% {
      opacity: 1;
    }
    60.2% {
      opacity: 0.2;
    }
    61% {
      opacity: 0.2;
    }
    61.02% {
      opacity: 1;
    }
    60.78% {
      opacity: 1;
    }
    60.8% {
      opacity: 0.4;
    }
    61.6% {
      opacity: 0.4;
    }
    61.62% {
      opacity: 1;
    }
    61.38% {
      opacity: 1;
    }
    61.4% {
      opacity: 0;
    }
    62.2% {
      opacity: 0;
    }
    62.22% {
      opacity: 1;
    }
    61.78% {
      opacity: 1;
    }
    61.8% {
      opacity: 1;
    }
    62.8% {
      opacity: 1;
    }
    62.82% {
      opacity: 1;
    }
    75.98% {
      opacity: 1;
    }
    76% {
      opacity: 1;
    }
    77% {
      opacity: 1;
    }
    77.02% {
      opacity: 1;
    }
    77.98% {
      opacity: 1;
    }
    78% {
      opacity: 0.7;
    }
    78.8% {
      opacity: 0.7;
    }
    78.82% {
      opacity: 1;
    }
    78.98% {
      opacity: 1;
    }
    79% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    80.02% {
      opacity: 1;
    }
    99.98% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
    101% {
      opacity: 1;
    }
    101.02% {
      opacity: 1;
    }
  }
  
  /* ----------------------------------------------
 * Generated by Animista on 2021-12-9 2:25:9
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  8% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  16% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  22% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  8% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  16% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  22% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
